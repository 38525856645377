import { State } from 'reducers'

export const SHOW_TOASTER = 'SHOW_TOASTER'
export const HIDE_TOASTER = 'HIDE_TOASTER'

export const showToaster =
  (status: string, title: string, message: string, timeout: number = 4000) =>
  (dispatch: any, getState: any) => {
    const state: State = getState()
    if (!state.toaster.showing) {
      dispatch({
        type: SHOW_TOASTER,
        status,
        title,
        message,
      })
      setTimeout(() => {
        dispatch(hideToaster())
      }, timeout)
    }
  }

export const hideToaster = () => (dispatch: any) => {
  dispatch({
    type: HIDE_TOASTER,
  })
}
