export const backgroundColor = '#FFFFFF'
export const containerColor = '#F7F9FD'
export const borderColor = '#4F457C'
export const textColor = '#29264C'
export const subTextColor = '#7E8794'
export const backgroundTextColor = '#F7F9FD'
export const placeholderColor = '#F7F9FD'
export const primaryColor = '#7068AA'
export const secondaryColor = '#73d7c3'
export const upColor = '#27AE60'
export const downColor = '#FF4343'
export const warningColor = '#FF7A00'
export const infoColor = '#00C2FF'
export const selectedColor = '#aea3ff3d'
